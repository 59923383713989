var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { type: "primary", size: "mini" },
          on: {
            click: () => {
              _vm.showDefaultSetting = true
            },
          },
        },
        [_vm._v(" 默认设置 ")]
      ),
      _vm.isShowPrice()
        ? _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "10px" },
              attrs: { size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.setAllDay("price")
                },
              },
            },
            [_vm._v(" 批量设置房价 ")]
          )
        : _vm._e(),
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { size: "mini" },
          on: {
            click: function ($event) {
              return _vm.setAllDay("stock")
            },
          },
        },
        [_vm._v(" 批量设置库存 ")]
      ),
      _c(
        "el-button",
        {
          staticStyle: { "margin-bottom": "10px" },
          attrs: { size: "mini" },
          on: {
            click: function ($event) {
              return _vm.setAllDay("close")
            },
          },
        },
        [_vm._v(" 批量设置房源状态 ")]
      ),
      _c("el-calendar", {
        staticClass: "my-calendar",
        scopedSlots: _vm._u([
          {
            key: "dateCell",
            fn: function ({ date, data }) {
              return [
                _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "calendar-card",
                      on: {
                        click: function ($event) {
                          return _vm.setOneDay(data.day)
                        },
                      },
                    },
                    [
                      _c("div", [
                        _c("span", { staticClass: "day" }, [
                          _vm._v(_vm._s(data.day.slice(5))),
                        ]),
                        _vm.holidayData[data.day]
                          ? _c(
                              "span",
                              {
                                style: {
                                  color:
                                    "nowMonth.getMonth() + 1 == data.day.slice(5, 7)"
                                      ? "#1890ff"
                                      : null,
                                },
                              },
                              [
                                _vm._v(
                                  "(" +
                                    _vm._s(_vm.holidayData[data.day].name) +
                                    ")"
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.holidayData[data.day]
                          ? _c(
                              "span",
                              {
                                style: {
                                  color:
                                    "nowMonth.getMonth() + 1 == data.day.slice(5, 7)"
                                      ? _vm.holidayData[data.day].holiday
                                        ? "#53ce4a"
                                        : "#e6a23c"
                                      : null,
                                },
                              },
                              [
                                _vm._v(
                                  "(" +
                                    _vm._s(
                                      _vm.holidayData[data.day].holiday
                                        ? "休"
                                        : "班"
                                    ) +
                                    ")"
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm.allDaysData[data.day]
                        ? _c(
                            "div",
                            [
                              _vm.isShowPrice()
                                ? _c("div", { staticClass: "num" }, [
                                    _vm._v(" 销售价(元)："),
                                    _c(
                                      "span",
                                      {
                                        class: [
                                          _vm.nowMonth.getMonth() + 1 ==
                                          data.day.slice(5, 7)
                                            ? "setting"
                                            : null,
                                        ],
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.allDaysData[data.day]
                                              ? _vm.allDaysData[data.day]
                                                  .sellPrice
                                              : 1
                                          ) + " "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "num" }, [
                                _vm._v(" 库存："),
                                _c("span", { staticClass: "" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.allDaysData[data.day] &&
                                        _vm.allDaysData[data.day]
                                          .stockQuantity > 0
                                        ? _vm.allDaysData[data.day]
                                            .stockQuantity
                                        : 0
                                    ) + " "
                                  ),
                                ]),
                              ]),
                              _c("div", [
                                _vm._v(" 房源状态： "),
                                _vm.allDaysData[data.day].stockQuantity > 0
                                  ? _c("span", [_vm._v("库存正常")])
                                  : _vm.allDaysData[data.day].stockQuantity ===
                                    0
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("售罄")]
                                    )
                                  : _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("房源关闭")]
                                    ),
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.allDaysData[data.day]
                                          .stockQuantity > 0,
                                      expression:
                                        "allDaysData[data.day].stockQuantity > 0",
                                    },
                                  ],
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.closeHome([data.day])
                                    },
                                  },
                                },
                                [_vm._v(" 关闭房源 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.allDaysData[data.day]
                                          .stockQuantity < 0,
                                      expression:
                                        "allDaysData[data.day].stockQuantity < 0",
                                    },
                                  ],
                                  attrs: { type: "primary", size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.openHome([data.day])
                                    },
                                  },
                                },
                                [_vm._v(" 开启房源 ")]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _vm.isShowPrice()
                                ? _c("div", { staticClass: "num" }, [
                                    _vm._v(" 销售价(元)："),
                                    _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.myDefaultPrice(date)) +
                                          " "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "num" }, [
                                _vm._v(" 库存："),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.defaultDay.stockQuantity > 0
                                        ? _vm.defaultDay.stockQuantity
                                        : 0
                                    ) + " "
                                  ),
                                ]),
                              ]),
                              _c("div", [
                                _vm._v(" 房源状态： "),
                                _vm.defaultDay.stockQuantity > 0
                                  ? _c("span", [_vm._v("库存正常")])
                                  : _vm.defaultDay.stockQuantity === 0
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("售罄")]
                                    )
                                  : _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("房源关闭")]
                                    ),
                              ]),
                              _vm._v(" "),
                              _c("br"),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.defaultDay.stockQuantity > 0,
                                      expression:
                                        "defaultDay.stockQuantity > 0",
                                    },
                                  ],
                                  attrs: { size: "mini" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.closeHome([data.day])
                                    },
                                  },
                                },
                                [_vm._v(" 关闭房源 ")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.defaultDay.stockQuantity < 0,
                                      expression:
                                        "defaultDay.stockQuantity < 0",
                                    },
                                  ],
                                  attrs: { size: "mini", type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.openHome([data.day])
                                    },
                                  },
                                },
                                [_vm._v(" 开启房源 ")]
                              ),
                            ],
                            1
                          ),
                    ]
                  ),
                ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.nowMonth,
          callback: function ($$v) {
            _vm.nowMonth = $$v
          },
          expression: "nowMonth",
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.changeType === "close" ? "房源状态设置" : "设置",
            visible: _vm.showSetting,
            width: "500px",
            "before-close": () => {
              _vm.showSetting = false
              _vm.checkDays = ""
            },
          },
          on: {
            "update:visible": function ($event) {
              _vm.showSetting = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "right", "label-width": "100px" } },
            [
              _vm.type == "all" && _vm.changeType === "close"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "状态设置:" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.closeType,
                            callback: function ($$v) {
                              _vm.closeType = $$v
                            },
                            expression: "closeType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v(" 关闭房源 "),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v(" 开启房源 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == "all"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选择方式:" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.changeTimeType },
                          model: {
                            value: _vm.timeType,
                            callback: function ($$v) {
                              _vm.timeType = $$v
                            },
                            expression: "timeType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v(" 按起止日期 "),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v(" 按日选择 "),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.timeType == 1,
                      expression: "timeType==1",
                    },
                  ],
                  attrs: { label: "日期:" },
                },
                [
                  _vm.type == "one"
                    ? _c("div", [_vm._v(" " + _vm._s(_vm.checkOneDay) + " ")])
                    : _vm._e(),
                  _vm.type == "all"
                    ? _c("el-date-picker", {
                        staticStyle: { width: "95%" },
                        attrs: {
                          type: "daterange",
                          align: "right",
                          "unlink-panels": "",
                          "range-separator": "至",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                          size: "small",
                        },
                        model: {
                          value: _vm.checkDays,
                          callback: function ($$v) {
                            _vm.checkDays = $$v
                          },
                          expression: "checkDays",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.timeType == 2,
                      expression: "timeType==2",
                    },
                  ],
                  attrs: { label: "日期:" },
                },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "dates",
                      placeholder: "选择日期",
                      size: "small",
                    },
                    model: {
                      value: _vm.checkDays,
                      callback: function ($$v) {
                        _vm.checkDays = $$v
                      },
                      expression: "checkDays",
                    },
                  }),
                ],
                1
              ),
              _vm.isShowPrice()
                ? [
                    _vm.type == "all" &&
                    _vm.timeType === 1 &&
                    _vm.changeType === "price"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "销售价区分:" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value: _vm.isDistinguish,
                                  callback: function ($$v) {
                                    _vm.isDistinguish = $$v
                                  },
                                  expression: "isDistinguish",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 0 } }, [
                                  _vm._v(" 不区分 "),
                                ]),
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v(" 区分平日、周末 "),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isDistinguish === 0 &&
                    (_vm.changeType === "price" || _vm.type == "one")
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "销售价（元）:" } },
                          [
                            _c("el-input-number", {
                              staticStyle: { width: "95%" },
                              attrs: {
                                min: 1,
                                max: 9999999,
                                label: "请输入销售价（元）",
                                size: "mini",
                              },
                              model: {
                                value: _vm.sellPrice,
                                callback: function ($$v) {
                                  _vm.sellPrice = $$v
                                },
                                expression: "sellPrice",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isDistinguish === 1 &&
                    _vm.timeType === 1 &&
                    _vm.changeType === "price"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "销售价（元）:" } },
                          _vm._l(_vm.weekList, function (item, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _vm._v(" " + _vm._s(item.title) + " "),
                                _c("el-input-number", {
                                  staticStyle: { width: "50%" },
                                  attrs: {
                                    min: 1,
                                    max: 9999999,
                                    label: "请输入销售价（元）",
                                    size: "mini",
                                  },
                                  model: {
                                    value: item.price,
                                    callback: function ($$v) {
                                      _vm.$set(item, "price", $$v)
                                    },
                                    expression: "item.price",
                                  },
                                }),
                                _vm._v(" 元 "),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm.changeType === "stock" || _vm.type == "one"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "库存:" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "95%" },
                        attrs: {
                          min: 0,
                          max: 9999999,
                          label: "请输入库存",
                          size: "mini",
                        },
                        model: {
                          value: _vm.stockQuantity,
                          callback: function ($$v) {
                            _vm.stockQuantity = $$v
                          },
                          expression: "stockQuantity",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.changeType !== "close"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.setDays },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
              _vm.changeType === "close"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.setCloseType },
                    },
                    [_vm._v("确 定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "默认设置",
            visible: _vm.showDefaultSetting,
            width: "500px",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": _vm.pageType !== "add",
            "before-close": () => {
              _vm.showDefaultSetting = false
            },
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDefaultSetting = $event
            },
            open: () => {
              _vm.getDefaultPrice()
            },
          },
        },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "right", "label-width": "100px" } },
            [
              _vm.isShowPrice()
                ? [
                    _c(
                      "el-form-item",
                      { attrs: { label: "销售价区分:" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.priceType,
                              callback: function ($$v) {
                                _vm.priceType = $$v
                              },
                              expression: "priceType",
                            },
                          },
                          [
                            _c("el-radio", { attrs: { label: 1 } }, [
                              _vm._v(" 不区分 "),
                            ]),
                            _c("el-radio", { attrs: { label: 2 } }, [
                              _vm._v(" 区分平日、周末 "),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.priceType === 1
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "销售价（元）:" } },
                          [
                            _c("el-input-number", {
                              staticStyle: { width: "95%" },
                              attrs: {
                                min: 1,
                                max: 9999999,
                                label: "请输入销售价（元）",
                                size: "mini",
                              },
                              model: {
                                value: _vm.sellPrice,
                                callback: function ($$v) {
                                  _vm.sellPrice = $$v
                                },
                                expression: "sellPrice",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.priceType === 2
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "销售价（元）:" } },
                          _vm._l(_vm.weekList, function (item, index) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _vm._v(" " + _vm._s(item.title) + " "),
                                _c("el-input-number", {
                                  staticStyle: { width: "50%" },
                                  attrs: {
                                    min: 1,
                                    max: 9999999,
                                    label: "请输入销售价（元）",
                                    size: "mini",
                                  },
                                  model: {
                                    value: item.price,
                                    callback: function ($$v) {
                                      _vm.$set(item, "price", $$v)
                                    },
                                    expression: "item.price",
                                  },
                                }),
                                _vm._v(" 元 "),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "库存:" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "95%" },
                    attrs: {
                      min: 0,
                      max: 9999999,
                      label: "请输入库存",
                      size: "mini",
                    },
                    model: {
                      value: _vm.stockQuantity,
                      callback: function ($$v) {
                        _vm.stockQuantity = $$v
                      },
                      expression: "stockQuantity",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.pageType === "add"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.onPreview },
                    },
                    [_vm._v("预 览")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.setDefaultDays(false)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.pageType !== "add" ? "确认修改" : "提交审核")
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.showGoods
        ? _c("ShowGoods", {
            attrs: {
              id: _vm.query.goodsId,
              "show-goods": _vm.showGoods,
              "goods-type": 2,
            },
            on: {
              closeShowGoods: function ($event) {
                _vm.showGoods = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }