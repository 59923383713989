<template>
  <div>
    <el-button
      style="margin-bottom: 10px"
      type="primary"
      size="mini"
      @click="() => {
        showDefaultSetting = true
      }"
    >
      默认设置
    </el-button>
    <el-button
      v-if="isShowPrice()"
      style="margin-bottom: 10px"
      size="mini"
      @click="setAllDay('price')"
    >
      批量设置房价
    </el-button>
    <el-button
      style="margin-bottom: 10px"
      size="mini"
      @click="setAllDay('stock')"
    >
      批量设置库存
    </el-button>
    <el-button
      style="margin-bottom: 10px"
      size="mini"
      @click="setAllDay('close')"
    >
      批量设置房源状态
    </el-button>

    <el-calendar
      v-model="nowMonth"
      class="my-calendar"
    >
      <template
        slot="dateCell"
        slot-scope="{date,data}"
      >
        <div>
          <div
            class="calendar-card"
            @click="setOneDay(data.day)"
          >
            <div>
              <span class="day">{{ data.day.slice(5) }}</span>
              <span
                v-if="holidayData[data.day]"
                :style="{'color': 'nowMonth.getMonth() + 1 == data.day.slice(5, 7)' ? '#1890ff':null}"
              >({{ holidayData[data.day].name }})</span>
              <span
                v-if="holidayData[data.day]"
                :style="{'color': 'nowMonth.getMonth() + 1 == data.day.slice(5, 7)' ? (holidayData[data.day].holiday ? '#53ce4a' : '#e6a23c') :null
                }"
              >({{ holidayData[data.day].holiday ? '休' : '班' }})</span>
            </div>
            <div v-if="allDaysData[data.day]">
              <div
                v-if="isShowPrice()"
                class="num"
              >
                销售价(元)：<span :class="[nowMonth.getMonth() + 1 == data.day.slice(5, 7)? 'setting':null]">{{
                  allDaysData[data.day] ? allDaysData[data.day].sellPrice : 1
                }} </span>
              </div>
              <!-- <div class="num ">
                市场价(元)：<span class=" ">{{
                  allDaysData[data.day] ? allDaysData[data.day].marketPrice : 1
                }} </span>
              </div> -->
              <div class="num ">
                库存：<span class=" ">{{
                  allDaysData[data.day] && allDaysData[data.day].stockQuantity > 0 ? allDaysData[data.day].stockQuantity : 0
                }} </span>
              </div>

              <div>
                房源状态：
                <span v-if="allDaysData[data.day].stockQuantity > 0">库存正常</span>
                <span
                  v-else-if="allDaysData[data.day].stockQuantity === 0"
                  style="color: red"
                >售罄</span>
                <span
                  v-else
                  style="color: red"
                >房源关闭</span>
              </div> <br />
              <el-button
                v-show="allDaysData[data.day].stockQuantity > 0"
                size="mini"
                @click.stop="closeHome([data.day])"
              >
                关闭房源
              </el-button>
              <el-button
                v-show="allDaysData[data.day].stockQuantity < 0"
                type="primary"
                size="mini"
                @click.stop="openHome([data.day])"
              >
                开启房源
              </el-button>
            </div>
            <div v-else>
              <div
                v-if="isShowPrice()"
                class="num"
              >
                销售价(元)：<span> {{ myDefaultPrice(date) }} </span>
              </div>
              <!-- <div class="num">
                市场价(元)：<span>{{ defaultDay.stockQuantity }} </span>
              </div> -->
              <div class="num">
                库存：<span>{{ defaultDay.stockQuantity > 0 ? defaultDay.stockQuantity : 0 }} </span>
              </div>
              <div>
                房源状态：
                <span v-if="defaultDay.stockQuantity > 0">库存正常</span>
                <span
                  v-else-if="defaultDay.stockQuantity === 0"
                  style="color: red"
                >售罄</span>
                <span
                  v-else
                  style="color: red"
                >房源关闭</span>
              </div> <br />
              <el-button
                v-show="defaultDay.stockQuantity > 0"
                size="mini"
                @click.stop="closeHome([data.day])"
              >
                关闭房源
              </el-button>
              <el-button
                v-show="defaultDay.stockQuantity < 0"
                size="mini"
                type="primary"
                @click.stop="openHome([data.day])"
              >
                开启房源
              </el-button>
            </div>
          </div>
        </div>
      </template>
    </el-calendar>
    <el-dialog
      :title="changeType === 'close' ? '房源状态设置' : '设置'"
      :visible.sync="showSetting"
      width="500px"
      :before-close="
        () => {
          showSetting = false;
          checkDays = '';
        }
      "
    >
      <el-form
        label-position="right"
        label-width="100px"
      >
        <el-form-item
          v-if="type == 'all' && changeType === 'close'"
          label="状态设置:"
        >
          <el-radio-group v-model="closeType">
            <el-radio :label="1">
              关闭房源
            </el-radio>
            <el-radio :label="2">
              开启房源
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="type == 'all'"
          label="选择方式:"
        >
          <el-radio-group
            v-model="timeType"
            @change="changeTimeType"
          >
            <el-radio :label="1">
              按起止日期
            </el-radio>
            <el-radio :label="2">
              按日选择
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-show="timeType==1"
          label="日期:"
        >
          <div v-if="type == 'one'">
            {{ checkOneDay }}
          </div>
          <el-date-picker
            v-if="type == 'all'"
            v-model="checkDays"
            style="width: 95%"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
          />
        </el-form-item>
        <el-form-item
          v-show="timeType==2"
          label="日期:"
        >
          <el-date-picker
            v-model="checkDays"
            type="dates"
            placeholder="选择日期"
            size="small"
          />
        </el-form-item>
        <template v-if="isShowPrice()">
          <el-form-item
            v-if="type == 'all' && timeType === 1 && changeType === 'price'"
            label="销售价区分:"
          >
            <el-radio-group
              v-model="isDistinguish"
            >
              <el-radio :label="0">
                不区分
              </el-radio>
              <el-radio :label="1">
                区分平日、周末
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="isDistinguish === 0 && (changeType === 'price' || type == 'one')"
            label="销售价（元）:"
          >
            <el-input-number
              v-model="sellPrice"
              style="width: 95%"
              :min="1"
              :max="9999999"
              label="请输入销售价（元）"
              size="mini"
            />
          </el-form-item>
          <el-form-item
            v-if="isDistinguish === 1 && timeType === 1 && changeType === 'price'"
            label="销售价（元）:"
          >
            <div
              v-for="(item,index) in weekList"
              :key="index"
            >
              {{ item.title }}  <el-input-number
                v-model="item.price"
                style="width: 50%"
                :min="1"
                :max="9999999"
                label="请输入销售价（元）"
                size="mini"
              />  元
            </div>
          </el-form-item>
        </template>
        <!-- <el-form-item label="市场价（元）:">
          <el-input-number
            v-model="marketPrice"
            style="width: 95%"
            :min="1"
            :max="9999999"
            label="请输入市场价（元）"
            size="mini"
          ></el-input-number>
        </el-form-item> -->

        <el-form-item
          v-if="changeType === 'stock' || type == 'one'"
          label="库存:"
        >
          <el-input-number
            v-model="stockQuantity"
            style="width: 95%"
            :min="0"
            :max="9999999"
            label="请输入库存"
            size="mini"
          />
        </el-form-item>
      </el-form>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          v-if="changeType !== 'close'"
          type="primary"
          size="small"
          @click="setDays"
        >确 定</el-button>
        <el-button
          v-if="changeType === 'close'"
          type="primary"
          size="small"
          @click="setCloseType"
        >确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="默认设置"
      :visible.sync="showDefaultSetting"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="pageType !== 'add'"
      :before-close="
        () => {
          showDefaultSetting = false;
        }
      "
      @open="() => {
        getDefaultPrice()
      }"
    >
      <el-form
        label-position="right"
        label-width="100px"
      >
        <template v-if="isShowPrice()">
          <el-form-item
            label="销售价区分:"
          >
            <el-radio-group v-model="priceType">
              <el-radio :label="1">
                不区分
              </el-radio>
              <el-radio :label="2">
                区分平日、周末
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="priceType === 1"
            label="销售价（元）:"
          >
            <el-input-number
              v-model="sellPrice"
              style="width: 95%"
              :min="1"
              :max="9999999"
              label="请输入销售价（元）"
              size="mini"
            />
          </el-form-item>
          <el-form-item
            v-if="priceType === 2"
            label="销售价（元）:"
          >
            <div
              v-for="(item,index) in weekList"
              :key="index"
            >
              {{ item.title }}  <el-input-number
                v-model="item.price"
                style="width: 50%"
                :min="1"
                :max="9999999"
                label="请输入销售价（元）"
                size="mini"
              />  元
            </div>
          </el-form-item>
        </template>
        <el-form-item label="库存:">
          <el-input-number
            v-model="stockQuantity"
            style="width: 95%"
            :min="0"
            :max="9999999"
            label="请输入库存"
            size="mini"
          />
        </el-form-item>
      </el-form>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          v-if="pageType ==='add'"
          type="primary"
          size="small"
          @click="onPreview"
        >预 览</el-button>
        <el-button
          type="primary"
          size="small"
          @click="setDefaultDays(false)"
        >{{ pageType !=='add' ? '确认修改' : '提交审核' }}</el-button>
      </span>
    </el-dialog>

    <ShowGoods
      v-if="showGoods"
      :id="query.goodsId"
      :show-goods="showGoods"
      :goods-type="2"
      @closeShowGoods="showGoods=false"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs';
import ShowGoods from '../components/goodsBase/components/showGoods.vue';

export default {
  components: {
    ShowGoods,
  },
  data() {
    return {
      showGoods: false,
      holidayData: {},
      nowMonth: new Date(),
      query: {},
      showSetting: false,
      showDefaultSetting: false,
      priceType: 1, // 1区分，2不区分
      isDistinguish: 0, // 0不区分，1区分,设置房价区分平日周末
      weekList: [{ title: '星期一', price: 0 }, { title: '星期二', price: 0 }, { title: '星期三', price: 0 }, { title: '星期四', price: 0 }, { title: '星期五', price: 0 }, { title: '星期六', price: 0 }, { title: '星期日', price: 0 }],
      type: '',
      checkOneDay: '',
      checkDays: '',
      marketPrice: 0, // 市场价
      sellPrice: 0, // 销售价
      stockQuantity: 0, // 库存
      allDaysData: {},
      defaultDay: {},
      timeType: 1, // 时间选择方式
      changeType: 'price', // stock
      pageType: '',
      skuId: '',
      closeType: 1, // 关闭房源还是开启房源
      isNotSale: false, // 是否非卖品
      skuData: [
        {
          goodsId: 0,
          id: 0,
          isDelete: 0,
          name: '日期',
          tradeSpecValueDtos: [
            {
              goodsId: 0,
              id: 0,
              isDelete: 0,
              name: '',
              specId: 0,
            },
          ],
        },
      ],
      skuTable: [
        {
          goodsId: 30309,
          id: 123424,
          imageIds: '',
          isDefault: 1,
          isDelete: 0,
          marketPrice: '4.00',
          minBuy: 4,
          promType: 0,
          sellPrice: '4.00',
          specKey: '107033',
          specValue: '民宿通66民宿通66',
          stockQuantity: 4,
        },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
    };
  },
  watch: {
    pageType: {
      handler(item) {
        if (item === 'add') {
          this.showDefaultSetting = true;
        } else {
          this.showDefaultSetting = false;
        }
      },
    },
  },
  mounted() {
    this.query = this.$route.query;
    this.isNotSale = ['2', '3'].includes(this.query.goodsBuyType);
    this.getAllData();
    this.getHoliday();
  },
  methods: {
    getHoliday() {
      let nowYear = new Date().getFullYear();
      for (let year of [nowYear, nowYear + 1]) {
        this.$axios.get(`https://timor.tech/api/holiday/year/${year}`).then((r) => {
          if (r.code === 0) {
            for (let day in r.holiday) {
              this.$set(this.holidayData, `${year}-${day}`, r.holiday[day]);
            }
          }
        });
      }
      // date: "2023-01-21"
      // holiday: true // true表示是节假日，false表示是调休
      // name: "除夕"
      // rest: 16 // 表示当前时间距离目标还有多少天。比如今天是 2018-09-28，距离 2018-10-01 还有3天
      // wage: 3  // 薪资倍数，1表示是1倍工资
    },
    myDefaultPrice(date) {
      if (this.defaultDay.isDistinguish === 1) {
        if (date.getDay() === 0) {
          return this.defaultDay.defaultPrice.split(',')[6];
        }
        return this.defaultDay.defaultPrice.split(',')[date.getDay() - 1];
      }
      return this.defaultDay.defaultPrice ? this.defaultDay.defaultPrice : '';
    },
    changeTimeType(e) {
      this.checkDays = '';
      this.isDistinguish = 0;
    },
    getAllData() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      });
      this.$axios
        .get(this.$api.goodsmanage.trade_goods_housePriceManage_view, {
          params: {
            goodsId: this.query.goodsId,
          },
        })
        .then((res) => {
          loading.close();
          if (res.code === 0) {
            if (res.data.length) {
              res.data.forEach((item) => {
                if (item.isDefault === 1) {
                  this.defaultDay = item;
                } else {
                  this.$set(this.allDaysData, item.specValue, item);
                }
              });
            } else {
              this.pageType = 'add';
            }
          }
        });
    },
    setDayApi(days) {
      let msgTitle = '是否确认修改价格?';
      if (this.changeType === 'stock') {
        msgTitle = '是否确认修改库存?';
      }
      this.$confirm(msgTitle, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.7)',
          });
          this.$axios
            .post(this.$api.goodsmanage.trade_goods_housePriceManage_edit, {
              goodsId: this.query.goodsId,
              // marketPrice: this.marketPrice,
              specValueList: days,
              price: (this.changeType === 'price' || this.type === 'one') && this.isDistinguish !== 1 ? this.sellPrice : undefined,
              stock: (this.changeType === 'stock' || this.type === 'one') ? this.stockQuantity : undefined, // 库存
              isDistinguish: (this.changeType === 'price' && this.type === 'all') ? this.isDistinguish : undefined, // 是否区分日期
              priceList: (this.changeType === 'price' && this.type === 'all') ? this.weekList.map((item) => {
                return item.price && item.price > 0 ? item.price : -1;
              }) : undefined, // 日期
            })
            .then((res) => {
              loading.close();
              if (res.code === 0) {
                this.$message({
                  message: '设置成功！',
                  type: 'success',
                });
                this.showSetting = false;
                this.getAllData();
              }
            });
        })
        .catch(() => {});
    },
    setAllDay(type) {
      this.type = 'all';
      this.changeType = type;
      this.showSetting = true;
    },
    setOneDay(day) {
      this.changeType = '';
      this.type = 'one';
      this.checkOneDay = day;
      this.isDistinguish = 0;
      const { marketPrice, sellPrice, stockQuantity } = this.defaultDay;
      this.marketPrice = this.allDaysData[day]
        ? this.allDaysData[day].marketPrice
        : marketPrice;
      this.sellPrice = this.allDaysData[day]
        ? this.allDaysData[day].sellPrice
        : sellPrice;
      this.stockQuantity = this.allDaysData[day] ? this.allDaysData[day].stockQuantity : stockQuantity;
      this.showSetting = true;
    },
    // 设置默认房价
    setDefaultDays(isShow) {
      if (this.isShowPrice() && this.priceType == 1 && !this.sellPrice) {
        this.$message({
          type: 'warning',
          message: '请设置销售价',
        });
        return;
      }
      if (this.priceType == 2) {
        for (let item of this.weekList) {
          if (item.price <= 0) {
            this.$message({
              type: 'warning',
              message: '请完善销售价大于0',
            });
            return;
          }
        }
      }
      if (!this.stockQuantity) {
        this.$message({
          type: 'warning',
          message: '请设置库存',
        });
        return;
      }
      let message = '是否确认修改？';
      if (this.pageType === 'add') {
        message = '是否确认设置并提交商品审核？';
      }
      if (isShow) {
        message = '确定保存商品信息并预览？';
      }
      this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.7)',
          });
          let params = {
            defaultPrice: this.priceType == 1 ? this.sellPrice : this.weekList.map((item) => item.price).join(),
            isDistinguish: this.priceType == 1 ? 0 : 1,
            skuId: this.pageType === 'add' ? 0 : this.skuId,
            stockQuantity: this.stockQuantity,
            goodsId: this.query.goodsId,
            flag: isShow ? 0 : 1,
          };
          this.$axios
            .post(this.$api.goodsmanage.housePriceSetting_edit, {
              ...params,
            })
            .then((res) => {
              loading.close();
              if (res.code === 0) {
                if (isShow) {
                  this.showGoods = true;
                  return;
                }
                this.$message({
                  message: '操作成功！',
                  type: 'success',
                });
                this.showDefaultSetting = false;
                if (this.pageType === 'add') {
                  this.$router.push('/nb/goods/index');
                } else {
                  this.getAllData();
                }
              }
            });
        })
        .catch(() => {});
    },
    getDefaultPrice() {
      this.$axios.get(this.$api.goodsmanage.housePriceSetting_view, {
        params: {
          goodsId: this.query.goodsId,
        },
      })
        .then((res) => {
          if (res.code === 0) {
            if (res.data.isDistinguish == 1) {
              this.priceType = 2;
              res.data.defaultPrice.split(',').forEach((item, index) => {
                this.$set(this.weekList[index], 'price', item);
              });
            } else {
              this.sellPrice = res.data.defaultPrice;
              this.priceType = 1;
            }
            this.stockQuantity = res.data.stockQuantity;
            this.skuId = res.data.skuId;
          }
        });
    },
    setDays() {
      // if (!this.marketPrice) {
      //   this.$message({
      //     type: 'warning',
      //     message: '请设置市场价'
      //   })
      //   return
      // }
      if (this.isShowPrice() && !this.sellPrice && this.changeType == 'price') {
        this.$message({
          type: 'warning',
          message: '请设置销售价',
        });
        return;
      }
      if (!this.stockQuantity && this.changeType == 'stock') {
        this.$message({
          type: 'warning',
          message: '请设置库存',
        });
        return;
      }
      // if (this.sellPrice > this.marketPrice) {
      //   this.$message({
      //     type: "warning",
      //     message: "设置销售价不能大于市场价！",
      //   });
      //   return;
      // }
      if (this.type == 'all') {
        if (!this.checkDays) {
          this.$message({
            type: 'warning',
            message: '请选择日期',
          });
          return;
        }

        let newDay = [];
        if (this.timeType === 1) { // 时间段
          let start = this.checkDays[0];
          let end = this.checkDays[1];
          newDay = this.getDays(start, end);
        } else if (this.timeType === 2) { // 单日
          this.checkDays.forEach((item) => {
            newDay.push(dayjs(item).format('YYYY-MM-DD'));
          });
        }
        this.setDayApi(newDay);
      } else if (this.type == 'one') {
        this.setDayApi([this.checkOneDay]);
      }
    },
    getDays(minDate, maxDate) {
      let startDate = new Date(minDate);
      let endDate = new Date(maxDate);
      let newDay = [];
      while (endDate.getTime() >= startDate.getTime()) {
        let year = startDate.getFullYear();
        let month = startDate.getMonth() + 1;
        let day = startDate.getDate();
        // 加一天
        startDate.setDate(day + 1);
        if (month.toString().length == 1) {
          month = `0${month}`;
        }
        if (day.toString().length == 1) {
          day = `0${day}`;
        }
        newDay.push(`${year}-${month}-${day}`);
      }
      return newDay;
    },
    // 关闭房源
    closeHome(days) {
      this.$confirm('是否确认关闭房源?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.7)',
          });
          this.$axios
            .post(this.$api.goodsmanage.trade_goods_closeHouse, {
              goodsId: this.query.goodsId,
              specValueList: days,
            })
            .then((res) => {
              loading.close();
              if (res.code === 0) {
                this.$message({
                  message: '设置成功！',
                  type: 'success',
                });
                this.getAllData();
              }
            });
        })
        .catch(() => {});
    },
    // 开启房源
    openHome(days) {
      this.$confirm('是否确认开启房源?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(255, 255, 255, 0.7)',
          });
          this.$axios
            .post(this.$api.goodsmanage.trade_goods_openHouse, {
              goodsId: this.query.goodsId,
              specValueList: days,
            })
            .then((res) => {
              loading.close();
              if (res.code === 0) {
                this.$message({
                  message: '设置成功！',
                  type: 'success',
                });
                this.getAllData();
              }
            });
        })
        .catch(() => {});
    },
    setCloseType() {
      if (!this.checkDays) {
        this.$message({
          type: 'warning',
          message: '请选择日期',
        });
        return;
      }
      let newDay = [];
      if (this.timeType === 1) { // 时间段
        let start = this.checkDays[0];
        let end = this.checkDays[1];
        newDay = this.getDays(start, end);
      } else if (this.timeType === 2) { // 单日
        this.checkDays.forEach((item) => {
          newDay.push(dayjs(item).format('YYYY-MM-DD'));
        });
      }
      if (this.closeType == 1) {
        this.closeHome(newDay);
      } else {
        this.openHome(newDay);
      }
    },
    isShowPrice() {
      return !this.isNotSale;
    },
    onPreview() {
      this.setDefaultDays(true);
    },
  },
};
</script>

<style lang="scss">
.my-calendar {
  .el-calendar__title {
    font-size: 18px;
    font-weight: bold;
  }
}
.el-calendar-table .el-calendar-day {
  height: auto;
}
.calendar-card {
  height: 100%;
  .day {
    font-weight: bold;
    line-height: 20px;
    font-size: 18px;
  }
  .num {
    font-size: 14px;
    line-height: 18px;
  }
  .setting {
    color: red;
  }
}
</style>
