// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.47.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.my-calendar .el-calendar__title {\n  font-size: 18px;\n  font-weight: bold;\n}\n.el-calendar-table .el-calendar-day {\n  height: auto;\n}\n.calendar-card {\n  height: 100%;\n}\n.calendar-card .day {\n  font-weight: bold;\n  line-height: 20px;\n  font-size: 18px;\n}\n.calendar-card .num {\n  font-size: 14px;\n  line-height: 18px;\n}\n.calendar-card .setting {\n  color: red;\n}", ""]);
// Exports
exports.locals = {
	"theme": "#30B08F",
	"menuBg": "#304156",
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF"
};
module.exports = exports;
